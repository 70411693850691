import styles from './App.module.css';
import { Profile } from './components/Profile';
import { Project } from './components/Project';

export const App = () => {
  return (
    <div className={styles.appContainer}>
      <div className={styles.app}>
        <Profile />

        <Project
          background="#62bc20"
          href="https://multislay.io"
          icon="/images/projects/multislay.gif"
          title="Multislay.io"
          subtitle="Multiplayer browser game that lets you shoot, stab, and
          blow up your friends!"
          tech={['TypeScript', 'Node', 'React', 'WebSockets', 'HTML Canvas']}
          when="2018 - Present"
          gif="/images/projects/multislay.gif"
        >
          I built this game because of a childhood dream of playing Zelda Four
          Swords online with friends. It runs on a client-server architecture
          over WebSockets rendered into an HTML canvas using a custom JavaScript
          game engine made by yours truly.
        </Project>

        <Project
          background="#00b3ff"
          href="https://gridshark.app"
          icon="/images/projects/gridshark.gif"
          title="Gridshark.app"
          subtitle="Daily pixel puzzles! Familiar with the 15 puzzle? Kinda like that but no missing square."
          tech={['TypeScript', 'React']}
          when="2020"
          gif="/images/projects/gridshark.gif"
        >
          I like puzzles, and I like pixel art, so I made a puzzly pixel art
          game!
        </Project>

        {/* <Project
          background="#778c00"
          href="https://fartsicle.com/"
          icon="/images/projects/fartsicle.svg"
          title="Fartsicle"
          subtitle="Slack app to /fart around with your team"
          tech={['JavaScript', 'Node', 'React']}
          when="2022"
        >
          I don't know about you, but I'm tired of teams that take everything
          way too seriously. C'mon, just chillax and play some games. Would love
          to expand on this app in the future. Currently I've got one game
          called Picture Telephone where players take turns drawing or
          captioning the drawings of their teammates to see how much an initial
          prompt will change over time.
        </Project> */}

        <Project
          background="linear-gradient(118deg, rgba(255,0,0,1) 0%, rgba(0,227,47,1) 35%, rgba(232,0,201,1) 69%, rgba(0,212,255,1) 100%)"
          href="https://github.com/jordoreed/tucunare"
          icon="/images/projects/tucu.gif"
          title="Tucunaré"
          subtitle="Super simple graphics rasterizer using the HTML canvas."
          tech={['TypeScript', 'HTML Canvas']}
          when="2016"
        >
          I'm fascinated by computer graphics and wanted to build a rasterizer
          from the ground up.
        </Project>

        <Project
          background="#931471"
          href="/projects/seniorproject"
          icon="/images/projects/ik.gif"
          title="Inverse Kinematics"
          subtitle="Teaching the computer how to walk."
          tech={['JavaScript', 'WebGL', 'HTML Canvas']}
          when="2015"
        >
          Why are graphics and animation so cool? I have no idea. For some
          reason I like this stuff. I wanted to make something animate for my
          senior project.
          <iframe
            src="/projects/seniorproject"
            width="600"
            height="400"
          ></iframe>
        </Project>

        <Project
          background="#8332fc"
          href="/projects/soaker460"
          icon="/images/projects/soaker460.png"
          title="Soaker 460"
          subtitle="Super lame networked water gun game for my networking class in college."
          tech={['C++', 'OpenGL', 'UDP Sockets']}
          when="2014"
          gif="/images/projects/soaker460-screenshot.png"
        >
          Fusing my passions of graphics and real-time interactions I made a
          super basic first-person shooter for my networking class in college.
        </Project>

        <Project
          background="#444444"
          href="/projects/belieb"
          icon="/images/projects/belieb.gif"
          title="Belieb"
          subtitle="Do you want to belieb and don't know how? Come, let me show you the way."
          tech={['JavaScript', 'HTML Canvas']}
          when="2014"
          gif="/images/projects/belieb.gif"
        >
          For those nonbeliebers out there, prepare to belieb.
        </Project>
      </div>
    </div>
  );
};

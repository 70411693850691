import React, { FC, useState } from 'react';
import styles from './index.module.css';

type ProjectProps = {
  background: string;
  href: string;
  icon: string;
  title: string;
  subtitle: string;
  tech: string[];
  when: string;
  gif?: string;
  children?: React.ReactNode;
};

export const Project: FC<ProjectProps> = ({
  background,
  href,
  icon,
  title,
  subtitle,
  tech,
  when,
  gif,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.project}>
      <a className={styles.link} href={href}>
        <header className={styles.header}>
          <img className={styles.image} src={icon} />
          <div className={styles.content}>
            <div className={styles.titles}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.subtitle}>{subtitle}</p>
            </div>

            <div className={styles.tech}>
              {tech.map((t, i) => (
                <div key={`${t}-${i}`} className={styles.techPill}>
                  {t}
                </div>
              ))}
            </div>
          </div>
        </header>
      </a>
    </div>
  );
};

import styles from './index.module.css';
import { FC } from 'react';

const linkedIn = 'https://www.linkedin.com/in/jordan-reed';

export const Profile: FC = () => {
  return (
    <div className={styles.profile}>
      <div className={styles.picContainer}>
        <img className={styles.pic} src="/images/me.jpg" />
        <div>
          <div className={styles.name}>Jordan Reed</div>
          <div className={styles.jobtitle}>Software Engineer</div>
        </div>
      </div>
      <p>
        I like to make stuff. Here you'll find a few of my pet projects. Hop
        over to my <a href={linkedIn}>LinkedIn</a> for my employment/career
        history.
      </p>

      <div className={styles.iconContainer}>
        <a href="https://github.com/jordoreed">
          <img
            src="/images/github/GitHub-Mark-120px-plus.png"
            className={styles.icon}
            alt="github icon"
          />
        </a>

        <a href={linkedIn} style={{ marginLeft: '1rem' }}>
          <img
            src="/images/linkedin/In-Black-128px-TM.png"
            className={styles.icon}
            alt="linkedin icon"
          />
        </a>
      </div>
    </div>
  );
};
